import Nav from "../components/Nav"

function NoPage(){
    return (
        <>
        <Nav/>
            <div>

                <h1 className="text-center font-custom text-5xl">THIS PAGE DOESN'T EXIST!</h1>


            </div>
        </>
    )
}


export default NoPage